@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Kanit-Regular';
  src: url('./assets/fonts/Kanit/Kanit-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'JetBrainsMono-VariableFont';
  src: url('./assets/fonts/JetBrains_Mono/JetBrainsMono-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('./assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@layer components { 

    body {
      @apply bg-gray-900;
      @apply text-gray-100;
      @apply font-body;
    } 

    h1,h2,h3,h4,h5,h6 {
      font-family: 'Kanit', sans-serif;
      font-weight: 700; 
    }

    h1 {
        @apply text-4xl;
    }

    h2 {
        @apply text-3xl;
    }

    h3 {
        @apply text-2xl;
    }

    h4 {
        @apply text-xl;
    }

    code {
      @apply bg-gray-800;
      @apply text-yellow-300;
      @apply font-code;
      @apply p-4;
      @apply rounded-lg;
      @apply text-xs;
    }

    .sticky-footer {
      @apply bg-gray-900;
      /* @apply fixed; */
      @apply inset-x-0;
      @apply bottom-0;
      @apply p-4;
    }

    .glow-yellow {
      text-shadow: rgb(34, 30, 13) 3px 0 20px; 
    }
    .link-common {
      @apply text-cyan-400 hover:text-yellow-300 underline hover:cursor-pointer
    }
    .btn-common {
      @apply m-1 p-1 text-base flex justify-center items-center cursor-pointer bg-slate-500 rounded-lg text-white hover:bg-slate-400 hover:text-slate-800 hover:border-slate-300 border-2 border-slate-100
    }
    .link-align-center {
      @apply text-center justify-center items-center;
    }
}
  